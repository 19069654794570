/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"

import * as THREE from '../assets/three.r92.min'
import GLOBE from '../assets/vanta.globe.min'

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  // TODO: Cleanup on dismount
  // use https://github.com/tengbao/vanta/issues/30 as example
  useEffect(() => {
    GLOBE({
      el: "#root",
      color: 0x3f82ff,
      size: 0.60,
      backgroundColor: 0x0,
      THREE,
    })
  }, [])

  return (
    <div id="root" className={'w-screen h-screen bg-black text-white'}>
      <div className="w-11/12 mx-auto h-full">
        <div className="flex flex-col h-full">
          <Header />
          <div className={'flex flex-col h-full justify-between'}>
            <main className={'flex h-full items-center'}>{children}</main>
            <footer className={'text-sm mb-8'}>
              <p>D-U-N-S: 08-011-5522</p>
              <p>Est. 2015 in Massachusetts (ID: 001201700)</p>
              <p>
                © 2020 P.F. Ventures, LLC.
              </p>
            </footer>
          </div>
        </div>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
