import { Link } from "gatsby"
import React from "react"

const Header = () => (
  <header>
    <div className={'h-16'}>
      <h1>
        <Link
          to="/"
          style={{
            textDecoration: `none`,
          }}
        >{/*pf*/}
        </Link>
      </h1>
    </div>
  </header>
)

export default Header
